import {useStaticQuery, graphql} from 'gatsby';
import styled from 'styled-components';
import React from 'react';
import {PageContent} from '../components/Layout';
import RichText from '../components/RichText';
import {useLocation} from '@reach/router';
import {PageContentContainer, Appear} from '../components/atoms';
import {devices} from '../styles/breakpoints';
import {NavigationContext} from '../services/navigation';
import {Helmet} from 'react-helmet';
import {StaticImage} from "gatsby-plugin-image"

const Container = styled(PageContent)`
  display: flex;
  text-align: justify;
`;

const CinembleContainer = styled(PageContentContainer)`
  display: flex;
  flex-direction: column;
  max-width: 1080px;
  padding: 0 24px;
  margin: 0 auto;
  width: 100%;
  flex: none;
  font-weight: 300;
  @media ${devices.tabletPortrait} {
    font-weight: 400;
    /* width: 80%; */
    align-items: center;
  }
`;


const Cinemble: React.FC = () => {
  const {setLocation} = React.useContext(NavigationContext);
  React.useEffect(() => {
    setLocation(window.location.pathname)
  }, [])
  const {state} = useLocation();
  const {strapi, cinembleOG} = useStaticQuery(graphql`
    query {
      cinembleOG: file(relativePath: {eq: "Cinemble1_512.png"}) {
        publicURL
      }
      strapi {
        cinemble {
          text
          seo {
            title
            description
            keywords {
              text
            }
          }
        }
      }
    }
  `);
  const {text, seo} = strapi.cinemble;
  const ref = React.useRef();
  return (
    <Container ref={ref}>
      <Helmet
        title={`Cinemble | Stavros Markonis`}
        meta={[
          {
            name: `description`,
            content: seo?.description,
          },
          {
            name: 'keywords',
            content: seo.keywords.map(({text}: {text: string}) => text).join(', '),
          },
          {
            property: `og:title`,
            content: `Cinemble | Stavros Markonis`,
          },
          {
            property: `og:description`,
            content: seo?.description,
          },
          {
            property: `og:type`,
            content: `website`,
          },
          {
            property: `og:image`,
            content: `${process.env.GATSBY_CLIENT_ORIGIN}${cinembleOG?.publicURL}`
          },
          {
            name: `twitter:card`,
            content: `summary`,
          },
          {
            name: `twitter:title`,
            content: `Cinemble | Stavros Markonis`,
          },
          {
            name: `twitter:description`,
            content:  seo?.description,
          },
        ]}
      />
      <Appear>
        <CinembleContainer translation={(state as any)?.enableTranslationTransition}>
          <StaticImage
            src="../images/Cinemble1_2K_no_background.png"
            alt="Cinemble logo"
            placeholder="blurred"
            layout="fixed"
            width={250}
            height={138}
            quality={30}
            style={{margin: '24px auto 96px'}}
          />
          <RichText source={text} />
        </CinembleContainer>
      </Appear>
    </Container>
  );
}

export default Cinemble;
